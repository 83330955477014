import React from "react"
import { useEffect } from "react"
import styles from "../Nutzungsbedingungen/Nutzungsbedingungen.module.scss"
import content from "../../content/content.json"

const Nutzungsbedingungen = () => {

	useEffect(() => {
		setTimeout(function () {
			window.scrollTo(0, 0)
        },250);
	})

    return (
        <section className={styles.Nutzungsbedingungen}>
            <article className={styles.Nutzungsbedingungen__Copy}>
                <h1>{content.pages.nutzungsbedingungen.headline}</h1>
                {content.pages.nutzungsbedingungen.list.map((entry, i) => 
                    <p dangerouslySetInnerHTML={{ __html: entry }}></p>
                )}
            </article>
        </section>
    );
}

export default Nutzungsbedingungen;